<template>
    <div class="practice">
        <iframe :src="reportUrl"  class="iframes"></iframe>
    </div>
</template>

<script>
import { has_buy, course_list,getLoginStatus } from "../../api/myself"
export default {
    name: 'practice',
    data() {
        return {
            reportUrl:""
           
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init(){
            
            getLoginStatus().then((res)=>{
                
                this.reportUrl = res.data.data;

            })
        }
    }
}
</script>

<style lang="less">
iframe {
  border: none;
}
.practice{
    width: 100%;
    height: 100vh;
    .iframes{
        width: 100%;
        height: 100%;
    }
}

</style>